/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import "~material-design-icons/iconfont/material-icons.css";
@import "@angular/material/theming";
@import "../node_modules/leaflet/dist/leaflet.css";
@import "../node_modules/leaflet-draw/dist/leaflet.draw.css";
// @include angular-material-theme($Default-theme);

@import "./assets/styles/material";
@import "./assets/styles/gridstack";

/*** this section is for resetPassword.html, forgotPassword.html, and resetPasswordSuccess.html**/
.container {
    width: 320px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    box-shadow: none;
    text-align: center;
    line-height: 20px;
}

[hidden] { display: none !important;}

.full-width {
    width: 100% !important;
}

.input-field {
    width: 100%;
}

// A container that centers its children.
.centered-container {
    display: flex;
    justify-content: center;
}

// Correctly align icon inside a button.
.btn-icon {
    margin-right: 8px;
    padding-bottom: 2px;
}

mat-list-option {
    border-radius: 8px;
    overflow: hidden;
    // Make the text un-selectable
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.menu-hide {
  display: none;
}

.scrollBelow {
    height: calc(100% - 120px);
    overflow: auto;
}

.scrollBelow-full-screen {
    height: calc(100% - 64px);
    overflow: auto;
}

.save-cancel {
    float: right;
    padding: 15px;
    position: absolute;
    bottom: -1%;
    right: 0;
}

.save-cancel button {
    margin: 3px;
    margin-right: 10px;
}

.float-right {
    float: right;
    padding: 15px;
    position: absolute;
    right: 0;
}

.float-right button {
    margin: 3px;
    margin-right: 10px;
}

.label {
  color: rgba(0, 0, 0, 0.54);
  font-family: Roboto;
}

.center-outside {
    text-align: center;
}

.center-inside {
    display: inline-block;
}

/* scrollbars */
::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    padding-left: 4px;
}

::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 4px;
}

/* dialog */

.toolbar-dialog > mat-dialog-container {
    padding: 0;
    overflow: hidden;
    font-family: "Roboto", "Helvetica Neue", sans-serif;
    max-width: 600px;
}

mat-dialog-container {
    border-radius: 12px !important;
}

.spacer {
    flex: 1 1 auto;
}

/*********THEMES*******/

@include mat.core();

//Default THEME
$Default-theme: mat.define-light-theme(
    mat.define-palette(mat.$indigo-palette, 500),
    mat.define-palette(mat.$grey-palette, 400),
    mat.define-palette(mat.$red-palette)
);
.Default {
    @include mat.all-component-themes($Default-theme);
}
//Red theme
$Red-theme: mat.define-light-theme(
    mat.define-palette(mat.$red-palette, 500),
    mat.define-palette(mat.$grey-palette, 400),
    mat.define-palette(mat.$red-palette)
);
.Red {
    @include mat.all-component-themes($Red-theme);
}
//Pink theme
$Pink-theme: mat.define-light-theme(
    mat.define-palette(mat.$pink-palette, 500),
    mat.define-palette(mat.$grey-palette, 400),
    mat.define-palette(mat.$red-palette)
);
.Pink {
    @include mat.all-component-themes($Pink-theme);
}
//Purple theme
$Purple-theme: mat.define-light-theme(
    mat.define-palette(mat.$purple-palette, 500),
    mat.define-palette(mat.$grey-palette, 400),
    mat.define-palette(mat.$red-palette)
);
.Purple {
    @include mat.all-component-themes($Purple-theme);
}
//Deep-Purple theme
$Deep-Purple-theme: mat.define-light-theme(
    mat.define-palette(mat.$deep-purple-palette, 500),
    mat.define-palette(mat.$grey-palette, 400),
    mat.define-palette(mat.$red-palette)
);
.Deep-Purple {
    @include mat.all-component-themes($Deep-Purple-theme);
}
//Blue theme
$Blue-theme: mat.define-light-theme(
    mat.define-palette(mat.$blue-palette, 500),
    mat.define-palette(mat.$grey-palette, 400),
    mat.define-palette(mat.$red-palette)
);
.Blue {
    @include mat.all-component-themes($Blue-theme);
}
//Light-Blue theme
$Light-Blue-theme: mat.define-light-theme(
    mat.define-palette(mat.$light-blue-palette, 500),
    mat.define-palette(mat.$grey-palette, 400),
    mat.define-palette(mat.$red-palette)
);
.Light-Blue {
    @include mat.all-component-themes($Light-Blue-theme);
}
//Cyan theme
$Cyan-theme: mat.define-light-theme(
    mat.define-palette(mat.$cyan-palette, 500),
    mat.define-palette(mat.$grey-palette, 400),
    mat.define-palette(mat.$red-palette)
);
.Cyan {
    @include mat.all-component-themes($Cyan-theme);
}
//Teal theme
$Teal-theme: mat.define-light-theme(
    mat.define-palette(mat.$teal-palette, 500),
    mat.define-palette(mat.$grey-palette, 400),
    mat.define-palette(mat.$red-palette)
);
.Teal {
    @include mat.all-component-themes($Teal-theme);
}
//Green theme
$Green-theme: mat.define-light-theme(
    mat.define-palette(mat.$green-palette, 500),
    mat.define-palette(mat.$grey-palette, 400),
    mat.define-palette(mat.$red-palette)
);
.Green {
    @include mat.all-component-themes($Green-theme);
}
//Light-Green theme
$Light-Green-theme: mat.define-light-theme(
    mat.define-palette(mat.$light-green-palette, 500),
    mat.define-palette(mat.$grey-palette, 400),
    mat.define-palette(mat.$red-palette)
);
.Light-Green {
    @include mat.all-component-themes($Light-Green-theme);
}
//Lime theme
$Lime-theme: mat.define-light-theme(
    mat.define-palette(mat.$lime-palette, 500),
    mat.define-palette(mat.$grey-palette, 400),
    mat.define-palette(mat.$red-palette)
);
.Lime {
    @include mat.all-component-themes($Lime-theme);
}
//Amber theme
$Amber-theme: mat.define-light-theme(
    mat.define-palette(mat.$amber-palette, 500),
    mat.define-palette(mat.$grey-palette, 400),
    mat.define-palette(mat.$red-palette)
);
.Amber {
    @include mat.all-component-themes($Amber-theme);
}
//Yellow theme
$Yellow-theme: mat.define-light-theme(
    mat.define-palette(mat.$yellow-palette, 500),
    mat.define-palette(mat.$grey-palette, 400),
    mat.define-palette(mat.$red-palette)
);
.Yellow {
    @include mat.all-component-themes($Yellow-theme);
}
//Orange theme
$Orange-theme: mat.define-light-theme(
    mat.define-palette(mat.$orange-palette, 500),
    mat.define-palette(mat.$grey-palette, 400),
    mat.define-palette(mat.$red-palette)
);
.Orange {
    @include mat.all-component-themes($Orange-theme);
}
//Orange theme
$Deep-Orange-theme: mat.define-light-theme(
    mat.define-palette(mat.$deep-orange-palette, 500),
    mat.define-palette(mat.$grey-palette, 400),
    mat.define-palette(mat.$red-palette)
);
.Deep-Orange {
    @include mat.all-component-themes($Deep-Orange-theme);
}
//Brown theme
$Brown-theme: mat.define-light-theme(
    mat.define-palette(mat.$brown-palette, 500),
    mat.define-palette(mat.$grey-palette, 400),
    mat.define-palette(mat.$red-palette)
);
.Brown {
    @include mat.all-component-themes($Brown-theme);
}
//Grey theme
$Grey-theme: mat.define-light-theme(
    mat.define-palette(mat.$grey-palette, 500),
    mat.define-palette(mat.$grey-palette, 400),
    mat.define-palette(mat.$red-palette)
);
.Grey {
    @include mat.all-component-themes($Grey-theme);
}
//Blue-Grey theme
$Blue-Grey-theme: mat.define-light-theme(
    mat.define-palette(mat.$blue-grey-palette, 500),
    mat.define-palette(mat.$grey-palette, 400),
    mat.define-palette(mat.$red-palette)
);
.Blue-Grey {
    @include mat.all-component-themes($Blue-Grey-theme);
}
.tooltip-outer {
    max-width: 350px;
    width: 350px;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0,0.5);
    background-color: #fff;
    border-radius: 8px;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(105%);
    padding: 10px;
    z-index: 9999;
    box-sizing: border-box;
    .widget-images {
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            height: 150px;
            width: 160px;
        }
    }
    p{
      font-size: 13px;
      line-height: 1.3;
      margin-bottom: 5px;
      margin-top: 0;
      font-family: $fontFamily;
      text-align: justify;
    }
    ul,ol{
      margin: 0;
      padding: 0;
      padding-left: 30px;
      li{
        font-size: 13px;
        line-height: 1.3;
        margin-bottom: 5px;
        font-family: $fontFamily;
      }
    }
    img{
      width: 100%;
      height: auto;
      object-fit: contain;
    }
    button{
      display:block;
      margin-left: auto !important;
      margin-right: 0;
      cursor: pointer;
      mat-icon{
        font-size: 15px;
      }
    }
  }
  
.tooltip-container {
    margin-bottom: 1vh;
    line-height: 1;
}
  
.tooltip-container:last-child {
    margin-bottom: 0;
}

.param-values {
    float: right;
    font-weight: bold;
    margin-left: 1rem;
}

.font-weight-bold {
    font-weight: bold;
}

// styles for statistics
$light-grey: #F7F7F7;
$light-blue: #2D3CBC;
$silver: #F5F5F5;
$table-header-background: #D9D9D9;
$table-border: #C8C8C8;

.cell-color {
    background: $light-grey;
    border: 0.063rem solid $table-border;
}

.header-color {
    background: $table-header-background;
    border: 0.063rem solid $table-border;
}

.statistics-save {
    background: $light-grey;
    display: flex;
    justify-content: space-between;
    padding: 0.625rem 1.875rem 0.625rem 1.875rem;
}

.device-name {
    color: $light-blue;
}

.statistics-tab .mat-tab-label-container {
    background: $silver;
}
